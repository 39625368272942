import React, { useEffect, useContext } from "react"
import { Formik, Form } from "formik"
import { FormControl, Box, TextField, Typography } from "@material-ui/core"
import Button from "../../../../Button"
import sentSVG from "../../../../../images/sent.svg"
import clsx from "clsx"
import { useStyles } from "./style"
import { Link, navigate } from "gatsby"
import AuthContext from "../../../../../context/auth/authContext"

const VerificationCheck = () => {
  const classes = useStyles()
  const authContext = useContext(AuthContext)

  const { loading, user, sendActivateEmail } = authContext

  const email = user ? user.email : ""
  const initialValue = {
    email,
  }

  useEffect(() => {
    user === null && navigate("/user/register")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = value => {
    sendActivateEmail(value)
  }

  return (
    <Box textAlign="center">
      <img src={sentSVG} alt="success" className={classes.successIcon} />
      <br />
      <br />
      <Typography align="center" className={classes.title}>
        Check your email
      </Typography>
      <Typography align="center" className={classes.subtitle}>
        {/* A link has been sent to {email}, Click the link  to verify your email and
        activate your account */}
        Thank you for creating an account with Bursery!
        <br />
        <br/>
         To finish setting up
        your account and continue with your application, please check your email
        to confirm the address you provided.
        <br />
        <br/>
         If you don't see our message,
        please check your spam folder!
      </Typography>
      <br />
      <br />
      <Formik initialValues={initialValue} onSubmit={onSubmit}>
        {({ values, getFieldProps }) => (
          <Form noValidate autoComplete="off" className={classes.form}>
            <FormControl
              className={classes.formControl}
              style={{ display: "none" }}
            >
              <TextField
                name="email"
                id="email"
                value={email}
                variant="outlined"
                size="small"
              />
            </FormControl>

            {/* {email && ( */}
            <FormControl className={classes.emailResend}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading}
                loading={loading}
              >
                Resend Mail
              </Button>
            </FormControl>
            {/* )} */}
          </Form>
        )}
      </Formik>
      <Link to="/user/register" style={{ textDecoration: "none" }}>
        <Typography
          align="center"
          className={clsx(classes.actionText, classes.text)}
        >
          Edit email address
        </Typography>
      </Link>
    </Box>
  )
}

export default VerificationCheck
