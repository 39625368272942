import React from 'react'
import SEO from "../../components/seo"
import AuthLayout from '../../components/Layout/AuthLayout'
import VerificationCheck from '../../components/Layout/AuthLayout/EmailCheck/VerificationCheck'

const verify = () => {
    return (
        <>
        <SEO title="Home" />
        <AuthLayout>
            <VerificationCheck />
        </AuthLayout>
            
        </>
    )
}

export default verify
